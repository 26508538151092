// extracted by mini-css-extract-plugin
export var header = "payload-module--header--d50f5";
export var headerBorder = "payload-module--header-border--5e815";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--2be59";
export var headerMobileBorder = "payload-module--header-mobile-border--9222b";
export var headerOuterWrapper = "payload-module--header-outer-wrapper--32beb";
export var headerTitleAndBorderContainer = "payload-module--header-title-and-border-container--0d8c0";
export var redirectingBoxesBlock = "payload-module--redirecting-boxes-block--b1353";
export var redirectingBoxesBlockTitle = "payload-module--redirecting-boxes-block-title--2d205";
export var redirectingBoxesBlocks = "payload-module--redirecting-boxes-blocks--3885d";
export var redirectingBoxesInnerWrapper = "payload-module--redirecting-boxes-inner-wrapper--c96ac";
export var redirectingBoxesSubtitleStyles = "payload-module--redirecting-boxes-subtitle-styles--22be7";
export var redirectingBoxesTitleStyles = "payload-module--redirecting-boxes-title-styles--7a766";
export var redirectingBoxesTitlesContainer = "payload-module--redirecting-boxes-titles-container--0542d";
export var textContactButtonSectionInnerWrapper = "payload-module--text-contact-button-section-inner-wrapper--f59a9";
export var underHeaderButton = "payload-module--under-header-button--18c1a";
export var underHeaderContentWrapper = "payload-module--under-header-content-wrapper--852f1";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--fef44";
export var underHeaderSliderInnerWrapper = "payload-module--under-header-slider-inner-wrapper--f4136";
export var underHeaderText = "payload-module--under-header-text--88921";
export var videoLinkSectionExtraText = "payload-module--video-link-section-extra-text--8e16f";
export var videoLinkSectionExtraTitle = "payload-module--video-link-section-extra-title--a7448";
export var videoLinkSectionInnerWrapper = "payload-module--video-link-section-inner-wrapper--d113c";
export var videoLinkSectionPrototype = "payload-module--video-link-section-prototype--abc5b";
export var videoLinkSectionSubtitleStyles = "payload-module--video-link-section-subtitle-styles--eed08";
export var videoLinkSectionTitlesContainer = "payload-module--video-link-section-titles-container--c86c6";