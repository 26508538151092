import React from 'react';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { Button } from '@commons/button/Button';

import * as styles from './text-contact-button.module.scss';

export const TextContactButtonSection = ({ text, buttonLabel, sectionProps }) => {
  return (
    <NewStandardSection classNames={{ innerWrapper: sectionProps?.innerWrapper }}>
      <div className={styles.mainContainer}>
        <span className={styles.containerWithText}>{text}</span>
        <Button label={buttonLabel} href="#contact" className={styles.button} />
      </div>
    </NewStandardSection>
  );
};
