import { navigate } from 'gatsby';

function useActivePage(location) {
  const params = new URLSearchParams(location.search);
  const page = params.get('page') ?? 1;

  function setCurrentPage(page) {
    navigate(location.pathname + `?page=${page}`);
  }

  return {
    activePage: page,
    currentPage: page,
    setCurrentPage: setCurrentPage,
  };
}

export default useActivePage;
