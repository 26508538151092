import { useMemo } from 'react';

export const DOTS = '...';
const firstPageIndex = 1;
const numberOfPagesToDisplay = 6;

const pagesToShow = (totalPageCount, start, end) => {
  return [...Array(totalPageCount).keys()].slice(start, end);
};

export const usePagination = (currentPage = 1, totalCount, pageSize, totalPages) => {
  const paginationRange = useMemo(() => {
    const lastPageIndex = totalPages;

    const leftSiblingIndex = currentPage - 1;
    const rightSiblingIndex = currentPage + 1;
    const shouldShowLeftDots = leftSiblingIndex > 3;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    //It will return array of number of pages [1,2,3] w/o dots

    if (numberOfPagesToDisplay >= totalPages) {
      return pagesToShow(totalPages + 1, 1);
    }

    // It will return an array e.g [1,2,3,4,5, dots, lastpage]

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const pagesToShowBeforeDots = pagesToShow(totalPages, 1, numberOfPagesToDisplay);
      return [...pagesToShowBeforeDots, DOTS, lastPageIndex - 1, lastPageIndex];
    }

    //It will return an array e.g [1, dots, 15,16,17,18,19]

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const pagesToShowAfterDots = pagesToShow(totalPages + 1, -5);
      return [firstPageIndex, firstPageIndex + 1, DOTS, ...pagesToShowAfterDots];
    }

    //it will return an array with dots in middle e.g [1, dots, 5,6,7, dots, lastpage]

    if (shouldShowLeftDots && shouldShowRightDots) {
      const pagesToShowBetweenDots = pagesToShow(totalPages, leftSiblingIndex, rightSiblingIndex + 1);
      return [
        firstPageIndex,
        firstPageIndex + 1,
        DOTS,
        ...pagesToShowBetweenDots,
        DOTS,
        lastPageIndex - 1,
        lastPageIndex,
      ];
    }
  }, [totalCount, pageSize, currentPage]);

  return paginationRange;
};
