import React from 'react';

import featuredImage from './images/featured-image.jpg';
import image1 from '@commons/redirecting-boxes-with-images-and-text/images/1.png';
import image2 from '@commons/redirecting-boxes-with-images-and-text/images/2.png';
import image3 from '@commons/redirecting-boxes-with-images-and-text/images/3.png';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Case studies | CodiLime',
  description:
    'Browse CodiLime case studies. Check out the outstanding services and solutions we deliver to our clients.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: <>Case studies</>,
  titleHasBorder: true,
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
    titleAndBorderContainer: styles.headerTitleAndBorderContainer,
    outerWrapper: styles.headerOuterWrapper,
    innerWrapper: styles.headerInnerWrapper,
  },
  titleBorderWidth: styles.headerMobileBorder,
};

export const sectionUnderHeaderProps = {
  textPartOne: (
    <>
      CodiLime works with a variety of clients across different industries and{' '}
      <b>helps them drive their business outcomes.</b> Our case studies showcase the specific challenges that our
      clients faced, the solutions we implemented, and the results they achieved.
    </>
  ),
  contactButtonLabel: ' Let’s talk',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
    contentWrapper: styles.underHeaderContentWrapper,
    text: styles.underHeaderText,
    button: styles.underHeaderButton,
    sliderInnerWrapper: styles.underHeaderSliderInnerWrapper,
  },
};

export const textContactButtonSectionProps = {
  text: 'Unlock the full potential of your product! Learn more about how we can help your business thrive.',
  buttonLabel: 'Let’s work together',
  sectionProps: { innerWrapper: styles.textContactButtonSectionInnerWrapper },
};

export const videoLinkSectionProps = {
  sectionProps: {
    title: 'Our demo examples',
    subtitle: 'See how our services can change your business in practice.',
  },
  collapseOptionOnMobile: true,
  extraTitle: 'Product Design',
  extraDescription:
    'Explore the clickable demo prepared in Figma, taste our design process, and see how we turn ideas into reality. Our Product Design team can build a high-fidelity clickable prototype that will showcase your product and streamline every step of the process.',
  classNames: {
    innerWrapper: styles.videoLinkSectionInnerWrapper,
    titlesContainer: styles.videoLinkSectionTitlesContainer,
    subtitleStyles: styles.videoLinkSectionSubtitleStyles,
    extraText: styles.videoLinkSectionExtraText,
    extraTitle: styles.videoLinkSectionExtraTitle,
    extraDescription: styles.videoLinkSectionExtraDescription,
    prototype: styles.videoLinkSectionPrototype,
  },
};

export const redirectingBoxesWithImagesAndTextProps = {
  sectionProps: {
    id: 'research-and-development',
    isH3: true,
    title: 'Research & Development',
    subtitle: (
      <>
        Our R&D team finds innovative solutions to the toughest challenges. Together we can develop a custom solution to
        meet your needs.
      </>
    ),
    classNames: {
      innerWrapper: styles.redirectingBoxesInnerWrapper,
      titlesContainer: styles.redirectingBoxesTitlesContainer,
      titleStyles: styles.redirectingBoxesTitleStyles,
      subtitleStyles: styles.redirectingBoxesSubtitleStyles,
    },
  },
  classNames: {
    blocks: styles.redirectingBoxesBlocks,
    blockTitle: styles.redirectingBoxesBlockTitle,
    block: styles.redirectingBoxesBlock,
  },
  collapseOptionOnMobile: true,
  expandByDefault: false,
  data: [
    {
      image: image1,
      title: 'Demo: Application networking in Kubernetes',
      textPartOne: (
        <span>We developed a demo that shows the implementation of a solitary entry point for numerous APIs, </span>
      ),
      textPartTwo: <span>which is based on Kubernetes Ingress with extended functionality.</span>,
      link: 'https://youtu.be/yfvE-9_m5_o',
      label: 'Demo',
    },
    {
      image: image2,
      title: 'PoC: Service Function Chaining for CNFs',
      textPartOne: (
        <span>We created a PoC for SFC (service function chaining) in CNFs (cloud-native network functions). </span>
      ),
      textPartTwo: (
        <span>Our approach offers flexibility in configuring CNFs to respond to ongoing network changes.</span>
      ),
      link: 'https://youtu.be/sLKQSK84DxY',
    },
    {
      image: image3,
      title: 'PoC: P4-programmable SmartNIC controlled by ONOS',
      textPartOne: (
        <span>Our team created a configuration that enabled us to manage the P4-programmable SmartNIC </span>
      ),
      textPartTwo: (
        <span>
          utilizing ONOS open-source software. As far as we know, this was one of the first or possibly the first
          solution of its kind globally.
        </span>
      ),
      link: 'https://youtu.be/IwjoggSEOmI',
    },
  ],
};

export const caseStudiesProps = {
  sectionProps: {
    id: 'our-case-studies',
    title: 'Check out our other case studies',
    subtitle:
      'Do you want to see more? Explore our case studies and see how we can help you achieve your business goal.',
  },
};

export const exploreOurCaseStudiesProps = {
  title: 'Explore our case studies',
  id: 'explore-our-case-studies',
};
