import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { safeMap } from '@src/utils/mapper';
import useOutsideClick from '@hooks/useOutsideClick';

import arrowUp from '@images/case-studies/arrow up.svg';
import arrowDown from '@images/case-studies/arrow down.svg';
import checkbox from '@images/case-studies/checkbox icon.svg';

import * as styles from './multiple-select-input.module.scss';

export const MultipleSelectInput = ({ options, values, onChange, label }) => {
  const [isVisible, setIsVisible] = useState(false);
  const menu = useRef();
  useOutsideClick(menu, () => setIsVisible(false));

  const onSelect = (option, e) => {
    e.preventDefault();
    if (values.some(({ value }) => value === option.value)) {
      onChange(values.filter(({ value }) => value !== option.value));
    } else {
      onChange([...values, option]);
    }
  };

  return (
    <div className={styles.multipleSelectWrapper} ref={menu}>
      <button
        className={cx(
          styles.selectGroupTypeWrapper,
          { [styles.visible]: isVisible },
          { [styles.count]: !values.length },
        )}
        onClick={() => setIsVisible(!isVisible)}
      >
        <span>{label}</span>
        {values.length > 0 && (
          <div className={styles.selectFilterCount}>
            <span>{values.length}</span>
          </div>
        )}
        <img loading="lazy" src={isVisible ? arrowUp : arrowDown} alt="arrow" />
      </button>
      <div className={cx(styles.selectOptions, { [styles.visible]: isVisible })}>
        {safeMap(options, ({ name, value }, index) => {
          const isActive = values.some((filter) => filter.value === value);
          return (
            <div onClick={(e) => onSelect({ value, name }, e)} key={index} className={styles.selectOption}>
              <div className={cx(styles.selectCheckbox, { [styles.active]: isActive })}>
                {isActive && <img loading="lazy" src={checkbox} alt="arrow" />}
              </div>
              <span>{name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const OptionsArrayType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
);

MultipleSelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  options: OptionsArrayType.isRequired,
  values: OptionsArrayType,
  onChange: PropTypes.func,
};
