import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import {
  DesktopHeaderLayoutB,
  MobileHeaderLayoutA,
  SectionUnderHeaderWithUnderline,
  NewClientsTestimonials,
  VideoLinkSection,
  RedirectingBoxesWithImagesAndText,
  ExploreOurCaseStudies,
} from '@src/components/commons';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  videoLinkSectionProps,
  redirectingBoxesWithImagesAndTextProps,
  textContactButtonSectionProps,
  caseStudiesProps,
  exploreOurCaseStudiesProps,
} from '@pages-impl/case-studies/payload';

import { TextContactButtonSection, CaseStudiesSection } from '@pages-impl/case-studies';

export default function Index(props) {
  return (
    <Layout {...props}>
      <SEO {...SEOProps} />
      <MobileHeaderLayoutA {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <ExploreOurCaseStudies {...exploreOurCaseStudiesProps} />
      <TextContactButtonSection {...textContactButtonSectionProps} />
      <NewClientsTestimonials />
      <VideoLinkSection {...videoLinkSectionProps} />
      <RedirectingBoxesWithImagesAndText {...redirectingBoxesWithImagesAndTextProps} />
      <CaseStudiesSection {...caseStudiesProps} {...props} />
    </Layout>
  );
}
