// extracted by mini-css-extract-plugin
export var background = "case-study-card-module--background--32f7c";
export var backgroundEnvironment = "case-study-card-module--background-environment--c6e6c";
export var backgroundEquipment = "case-study-card-module--background-equipment--a2657";
export var backgroundNetworks = "case-study-card-module--background-networks--59ce8";
export var backgroundOther = "case-study-card-module--background-other--e6d23";
export var backgroundSecurity = "case-study-card-module--background-security--eec96";
export var card = "case-study-card-module--card--92bfe";
export var category = "case-study-card-module--category--55723";
export var content = "case-study-card-module--content--e365f";
export var info = "case-study-card-module--info--b1be4";
export var line = "case-study-card-module--line--b50c4";
export var needsName = "case-study-card-module--needs-name--33bd4";
export var needsNameContainer = "case-study-card-module--needs-name-container--32796";
export var services = "case-study-card-module--services--a4b9f";
export var title = "case-study-card-module--title--8ae04";
export var type = "case-study-card-module--type--1ac89";
export var typeName = "case-study-card-module--type-name--d576e";