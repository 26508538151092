import React from 'react';

import { usePagination, DOTS } from '@src/hooks/usePagination';
import leftArrow from '@images/left-arrow.svg';
import rightArrow from '@images/right-arrow.svg';

import cx from 'classnames';
import * as styles from './pagination-control.module.scss';
import { Link } from '@commons/link/Link';

const firstPage = 1;

const PaginationControls = ({
  to,
  onPageChange,
  totalCount,
  activePage,
  pageSize,
  firstPageSize,
  currentPage,
  isButton,
}) => {
  const lastPage = Math.ceil((totalCount - (firstPageSize || pageSize)) / pageSize + 1);
  const paginationRange = usePagination(currentPage, totalCount, pageSize, lastPage);

  if (lastPage < activePage) {
    onPageChange?.(1);
  }

  const onNext = () => {
    onPageChange?.(Number(activePage) + 1 || 2);
  };

  const onPrevious = () => {
    onPageChange?.(Number(activePage) - 1);
  };

  const getDestinationPath = (pageNumber) => {
    if (to) {
      return to(pageNumber);
    } else {
      return `?page=${pageNumber}`;
    }
  };

  return (
    <div className={styles.paginationContainer}>
      <ChangePageLink
        disabled={Number(activePage) === firstPage}
        changePage={onPrevious}
        arrow={leftArrow}
        page={Number(currentPage) - 1}
        isButton={true}
        to={to}
      />
      {paginationRange?.map((pageNumber, index) =>
        pageNumber === DOTS ? (
          <span key={index} className={cx(styles.paginationItem, styles.dots)}>
            {DOTS}
          </span>
        ) : !isButton ? (
          <Link
            href={getDestinationPath(pageNumber)}
            key={index}
            className={cx(styles.paginationItem, {
              [styles.selected]: pageNumber === Number(activePage),
            })}
          >
            {pageNumber}
          </Link>
        ) : (
          <button
            key={index}
            className={cx(styles.paginationItem, {
              [styles.selected]: pageNumber === Number(activePage),
            })}
            onClick={() => onPageChange?.(pageNumber)}
          >
            {pageNumber}
          </button>
        ),
      )}
      <ChangePageLink
        disabled={Number(activePage) === lastPage}
        changePage={onNext}
        arrow={rightArrow}
        page={Number(currentPage) + 1}
        isButton={true}
        to={to}
      />
    </div>
  );
};

const ChangePageLink = ({ to, disabled, changePage, arrow, page, isButton }) => {
  const destinationPath = to?.(page) ?? `?page=${page}`;

  return !isButton ? (
    <Link
      href={destinationPath}
      className={cx(styles.paginationItem, styles.paginationArrow, {
        [styles.disabled]: disabled,
      })}
    >
      <img loading="lazy" src={arrow} alt="arrow" />
    </Link>
  ) : (
    <button
      className={cx(styles.paginationItem, styles.paginationArrow, {
        [styles.disabled]: disabled,
      })}
      onClick={changePage}
    >
      <img loading="lazy" src={arrow} alt="arrow" />
    </button>
  );
};

export default PaginationControls;
