import React, { useMemo, useState, useEffect } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import { useWindowSize } from '@hooks/useWindowSize';
import { filteredCaseStudies } from '@src/utils/parsers';
import useActivePage from '@src/hooks/useActivePage';
import { InputToFilter } from '@src/components/commons';
import { MultipleSelectInput } from '@commons/multiple-select-input/MultipleSelectInput';
import PaginationControls from '@commons/pagination/PaginationControls';
import { NewStandardSection } from '@src/components/commons';

import { getCaseStudiesOptions } from '@src/utils/caseStudiesOptions';
import { CaseStudyCard } from './case-study-card/CaseStudyCard';
import { ResetButton } from './reset-button/ResetButton';

import * as styles from './case-studies-section.module.scss';

export function CaseStudiesSection({ location, sectionProps }) {
  const caseStudiesQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { layout: { eq: "caseStudies" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              path
              category
              needs
              keywords
              searchKeywords
              caseStudyId
              description
              serviceType
              caseStudyName
              clientTypeName
            }
          }
        }
      }
    }
  `);

  const { services } = getCaseStudiesOptions();
  const windowSize = useWindowSize();
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    const windowWidth = windowSize.width;
    if (windowWidth < 601) {
      setPageSize(3);
    } else if (windowWidth < 1024) {
      setPageSize(4);
    } else if (windowWidth < 1440) {
      setPageSize(6);
    } else {
      setPageSize(8);
    }
  }, [windowSize.width]);

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedFilters, setSelectedFilters] = useState({
    services: [],
  });

  const allCaseStudies = useMemo(() => {
    const caseStudiesQueries = caseStudiesQuery.allMarkdownRemark;
    return filteredCaseStudies(caseStudiesQueries, selectedFilters.services, searchTerm);
  }, [selectedFilters, searchTerm]);

  const sortedDataForDropdown = useMemo(() => {
    const resultArray = [];
    allCaseStudies.forEach((object) => {
      resultArray.push(...object.categories, object.title, ...object.services, ...(object.needs || []));
    });
    return [...new Set(resultArray.sort())];
  }, [allCaseStudies, searchTerm]);

  const { activePage, currentPage, setCurrentPage } = useActivePage(location);

  const currentCaseStudies = useMemo(() => {
    const firstPageIndex = (currentPage - 1 || 0) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return allCaseStudies.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, allCaseStudies, pageSize]);

  const setToFirstPage = () => {
    setCurrentPage(1);
    navigate(`?page=1`);
  };

  const chooseServices = (services) => {
    setSelectedFilters({ services: services });
    setToFirstPage();
  };

  const inputSearchHandler = (event) => {
    setSearchTerm(event.target.value);
    setToFirstPage();
  };

  const resetInput = () => {
    setSearchTerm('');
  };

  const resetAll = () => {
    setSearchTerm('');
    setSelectedFilters({ services: [] });
  };

  return (
    <NewStandardSection
      {...sectionProps}
      classNames={{
        innerWrapper: styles.innerWrapper,
        titlesContainer: styles.titlesContainer,
        subtitleStyles: styles.subtitleStyles,
      }}
    >
      <div className={styles.searchFilters}>
        <MultipleSelectInput
          options={services}
          onChange={chooseServices}
          values={selectedFilters.services}
          label="Services"
        />
        <div className={styles.customInputContainer}>
          <InputToFilter
            id="resources-input"
            listName="resources-option"
            value={searchTerm}
            onChangeHandler={inputSearchHandler}
            sortedListOptionsForInput={sortedDataForDropdown || []}
            onClickCrossHandler={resetInput}
            customStyles={styles.inputToFilter}
          />
        </div>
        <ResetButton onClickHandler={resetAll} />
      </div>
      <div className={styles.searchResults}>
        {currentCaseStudies.map((data, index) => (
          <CaseStudyCard
            key={index}
            {...data}
            needs={data.needs ? data.needs[0] : 'Other'}
            classNames={{ card: styles.singleSearchResult }}
          />
        ))}
      </div>
      {allCaseStudies.length > 6 && (
        <PaginationControls
          totalCount={allCaseStudies.length}
          currentPage={currentPage}
          pageSize={pageSize}
          activePage={activePage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </NewStandardSection>
  );
}
