import { graphql, useStaticQuery } from 'gatsby';

export const getCaseStudiesOptions = () => {
  const queryResult = useStaticQuery(graphql`
    query {
      serviceTypes: allMarkdownRemark(filter: { frontmatter: { layout: { eq: "serviceTypes" } } }) {
        nodes {
          frontmatter {
            serviceTypeName
          }
        }
      }
    }
  `);

  const sortedServices = queryResult.serviceTypes.nodes.sort((a, b) =>
    a.frontmatter.serviceTypeName.localeCompare(b.frontmatter.serviceTypeName),
  );

  const sortGroupTypes = (sortedFilters, frontmatterType) => {
    const sortedGroupType = sortedFilters.map((filter, index) => {
      return {
        name: filter.frontmatter[frontmatterType],
        value: index,
      };
    });
    return sortedGroupType;
  };

  return {
    services: sortGroupTypes(sortedServices, 'serviceTypeName'),
  };
};
