import React from 'react';

import cx from 'classnames';

import { Link } from '@commons/link/Link';

import * as styles from './case-study-card.module.scss';

export const CaseStudyCard = ({
  link,
  description,
  categories,
  services,
  needs = 'Other',
  classNames: { card } = {},
}) => {
  return (
    <Link className={cx(styles.card, card)} href={link}>
      <div
        className={cx(styles.background, {
          [styles.backgroundEnvironment]: needs === 'Environment',
          [styles.backgroundNetworks]: needs === 'Networks',
          [styles.backgroundSecurity]: needs === 'Security',
          [styles.backgroundEquipment]: needs === 'Equipment',
          [styles.backgroundOther]: needs === 'Other',
        })}
      >
        <div className={styles.needsNameContainer}>
          <span className={styles.needsName}>{needs}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{description}</div>
        <hr className={styles.line} />
        <div className={styles.info}>
          <div className={styles.type}>
            <span className={styles.typeName}>Categories</span>
            {categories.map((category, index) => (
              <span className={styles.category} key={category}>
                {index !== 0 && <span> | </span>}
                {category}
              </span>
            ))}
          </div>
          <div className={styles.type}>
            <span className={styles.typeName}>Service types</span>
            {services.map((service, index) => (
              <span className={styles.services} key={service}>
                {index !== 0 && <span> &nbsp;|&nbsp;</span>}
                {service}
              </span>
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
};
